export const handleAdClick = async (adName, baseUrl) => {
    try {
        const response = await fetch(`${baseUrl}/track-click`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ad_name: adName }),
        });
        const data = await response.json();
        if (data.status === 'success') {
            console.log(`Click count for ${adName}: ${data.click_count}`);
        } else {
            console.error(data.message);
        }
    } catch (error) {
        console.error('Error tracking click:', error);
    }
};
