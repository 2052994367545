import '../App.css';
import React, { useState, useEffect } from 'react';
import TrackPageVisits from './TrackPageVisits';
import Head from './Head';
import BusinessCards from './BusinessCards';

function App() {
  const [domain, setDomain] = useState('');
  const [city, setCity] = useState('Loading...');
  const [us_state, setUsState] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const debug = process.env.NODE_ENV !== 'production';

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const locationMap = {
      'trentonmohub.com': { 'city': 'Trenton', 'state': 'Missouri' },
      'www.trentonmohub.com': { 'city': 'Trenton', 'state': 'Missouri' },
      'carrolltonmohub.com': { 'city': 'Carrollton', 'state': 'Missouri' },
      'www.carrolltonmohub.com': { 'city': 'Carrollton', 'state': 'Missouri' },
      'chillicothemohub.com': { 'city': 'Chillicothe', 'state': 'Missouri' },
      'www.chillicothemohub.com': { 'city': 'Chillicothe', 'state': 'Missouri' },
      'jamesportmohub.com': { 'city': 'Jamesport', 'state': 'Missouri' },
      'www.jamesportmohub.com': { 'city': 'Jamesport', 'state': 'Missouri' },
      'grandislandnehub.com': { 'city': 'Grand Island', 'state': 'Nebraska' },
      'www.grandislandnehub.com': { 'city': 'Grand Island', 'state': 'Nebraska' },
      'localhost': { 'city': 'Trenton', 'state': 'Missouri' },
    };

    setDomain(currentDomain);
    setBaseUrl(currentDomain === 'localhost' ? 'http://127.0.0.1:5000/api' : `https://${currentDomain}/api`);
    setCity(locationMap[currentDomain]['city'] || 'Unknown Location');
    setUsState(locationMap[currentDomain]['state'] || 'Unknown State');
  }, []);

  return (
    <div>
      <TrackPageVisits location={city} debug={debug} baseUrl={baseUrl} />
      <Head city={city} domain={domain} state={us_state} />
      <BusinessCards city={city} baseUrl={baseUrl} state={us_state} />
    </div>
  );
}

export default App;
