import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import BusinessCard from './BusinessCard';
import DynamicTitle from './DynamicTitle';
import StatsContainer from './StatsContainer';
import { handleAdClick } from '../utils/api';
import Lottie from 'react-lottie-player';
import SearchBar from './SearchBar';


// import App.css
import '../App.css';
import CircularProgress from '@mui/material/CircularProgress';

function BusinessCards({ city, baseUrl, state }) {
    const [ads, setAds] = useState([]);
    const [filteredAds, setFilteredAds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const lastVisibleIdRef = useRef(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermRef = useRef('');

    // Update the ref whenever searchTerm changes
    useEffect(() => {
        searchTermRef.current = searchTerm;
    }, [searchTerm]);

    const renderedAds = useMemo(() => {
        return filteredAds.map((ad) => {
            return (
                <Grid key={ad.name} xs={12} sm={6} md={4}>
                    <Link href={ad.url} target="_blank" underline="none" onClick={() => handleAdClick(ad.name, baseUrl)}>
                        <BusinessCard ad={ad} />
                    </Link>
                </Grid>
            );
        },);
    }, [filteredAds, baseUrl]);

    const preloadImages = (adsToPreload) => {
        return Promise.all(
            adsToPreload.map((ad) => {
                // if ad.imageUrl is not defined then return an empty promise
                if (!ad.imageUrl) return Promise.resolve();

                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = ad.imageUrl;
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            })
        );
    };

    const fetchAds = useCallback(async () => {
        if (!baseUrl || loading || !hasMore) return;

        setLoading(true);
        setError(null);
        try {
            const query = lastVisibleIdRef.current && lastVisibleIdRef.current !== 'undefined'
                ? `?last_visible_id=${encodeURIComponent(lastVisibleIdRef.current)}`
                : '';

            const response = await axios.get(`${baseUrl}/ads${query}`);
            const searchTerm = searchTermRef.current;
            await preloadImages(response.data.ads);


            setAds((prevAds) => {
                const updatedAds = [...prevAds, ...response.data.ads];

                if (searchTerm) {
                    const filteredNewAds = updatedAds.filter((item) => {
                        const itemDescription = item.description ? item.description.toLowerCase() : '';
                        const itemName = item.name ? item.name.toLowerCase() : '';
                        return (
                            itemDescription.includes(searchTerm.toLowerCase()) ||
                            itemName.includes(searchTerm.toLowerCase())
                        );
                    });

                    // Only update filteredAds if the filtered list has changed
                    setFilteredAds((prevFilteredAds) =>
                        JSON.stringify(prevFilteredAds) !== JSON.stringify(filteredNewAds) ? filteredNewAds : prevFilteredAds
                    );
                } else {
                    // Only update filteredAds if the ads list has changed
                    setFilteredAds((prevFilteredAds) =>
                        JSON.stringify(prevFilteredAds) !== JSON.stringify(updatedAds) ? updatedAds : prevFilteredAds
                    );
                }

                return updatedAds;
            });


            lastVisibleIdRef.current = response.data.last_visible_id;
            setHasMore(response.data.ads.length > 0);
        } catch (err) {
            console.error('Failed to fetch ads:', err);
            setError('Failed to load ads. Please try again later.');
        } finally {
            setLoading(false);
            setInitialLoading(false);
        }
    }, [baseUrl, loading, hasMore]);



    useEffect(() => {
        // Fetch ads on component mount and when `baseUrl` is ready
        if (baseUrl) {
            fetchAds();
        } else {
        }
    }, [baseUrl, fetchAds]);

    useEffect(() => {
        let throttleTimeout = null;

        const throttledHandleScroll = () => {
            if (throttleTimeout) return; // Skip execution if already throttled

            throttleTimeout = setTimeout(() => {
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

                const scrollPosition = (scrollTop + clientHeight) / scrollHeight;

                if (scrollPosition >= 0.8 && !loading && hasMore) {
                    fetchAds(searchTerm);
                }

                throttleTimeout = null; // Reset throttle
            }, 200); // Throttle interval in milliseconds
        };

        window.addEventListener('scroll', throttledHandleScroll);

        return () => {
            clearTimeout(throttleTimeout);
            window.removeEventListener('scroll', throttledHandleScroll);
        };
    }, [loading, hasMore, searchTerm, fetchAds]); // Ensure all dependencies are included



    if (initialLoading) {
        // Render Lottie animation during the initial fetch
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#ffffff',
                }}
            >
                <Lottie
                    loop
                    animationData={require('../assets/loading.json')}
                    play
                    style={{ width: 300, height: 300 }}
                />
            </Box>
        );
    }

    if (error) {
        return <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>;
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                padding: 0,
                backgroundColor: '#ffffff', // Ensures no gray background
                minHeight: '100vh',
                width: '100%',
            }}
        >
            <DynamicTitle city={city} state={state} />
            <StatsContainer baseUrl={baseUrl} />
            <SearchBar data={ads} setFilteredAds={setFilteredAds} fetchMoreAds={fetchAds}
                hasMore={hasMore} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="start"
                sx={{
                    padding: '20px', // Add padding to the Grid
                }}
            >
                {renderedAds}
            </Grid>
            {/* Small Loading Indicator */}
            {loading && hasMore && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress color="inherit" size={20} />
                </Box>)}
        </Box>
    );
}

export default BusinessCards;
