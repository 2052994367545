import React, { useState, useEffect } from 'react';
import StatsBox from './StatsBox';
import '../Stats.css';

const StatsContainer = ({ baseUrl }) => {
    const [visitCount, setVisitCount] = useState(0);
    const [clickCount, setClickCount] = useState(0);

    useEffect(() => {

        if (baseUrl === '') {
            return;
        }

        // Fetch stats from the server
        const fetchStats = async () => {
            try {
                const response = await fetch(`${baseUrl}/stats`);
                if (!response.ok) {
                    throw new Error('Failed to fetch stats');
                }
                const data = await response.json();
                setVisitCount(data.visit_count);
                setClickCount(data.click_count);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, [baseUrl]);

    return (
        <div className="stats-container">
            <StatsBox icon="👁️" count={visitCount} label="Page Visits" />
            <StatsBox icon="🔗" count={clickCount} label="Link Clicks" />
        </div>
    );
};

export default StatsContainer;
