import React from 'react';

const DynamicTitle = ({ city, state }) => {
    return (
        <h1
            style={{
                textAlign: 'center',
                color: '#333',
                marginBottom: '24px',
                fontFamily: 'Arial, sans-serif',
            }}
        >
            {city} {state} Hub
        </h1>
    );
};

export default DynamicTitle;
