import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

function BusinessCard({ ad }) {
    const [imageLoaded, setImageLoaded] = useState(false);

    const CardContainer = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minWidth: '300px',
        maxWidth: '300px',
        margin: '0 auto',
        padding: theme.spacing(2),
        borderRadius: '12px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
        },
    }));

    const Image = styled('img')({
        width: '100%',
        height: ad.size === 'large' ? '200px' : 'auto',
        borderRadius: '8px 8px 0 0',
        objectFit: 'contain',
        marginBottom: '16px',
        display: imageLoaded ? 'block' : 'none', // Hide image until loaded
    });

    const Placeholder = styled('div')(({ theme }) => ({
        width: '100%',
        height: '200px',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px 8px 0 0',
        marginBottom: '16px',
        display: imageLoaded ? 'none' : 'block', // Show placeholder until image loads
        animation: 'pulse 1.5s infinite',
    }));

    const Content = styled('div')({
        flex: 1,
        textAlign: 'center',
    });

    return (
        <CardContainer>
            {/* Image */}
            {ad.size === 'large' && ad.image_url && (
                <>
                    {/* Placeholder */}
                    <Placeholder />
                    <Image
                        src={ad.image_url}
                        alt={ad.name}
                        onLoad={() => setImageLoaded(true)} // Mark as loaded
                        onError={() => setImageLoaded(true)} // Handle error state
                    />
                </>
            )}

            {/* Content */}
            <Content>
                <h2 style={{ margin: 0, color: '#333' }}>{ad.name}</h2>
                <p style={{ margin: '8px 0', color: '#555', wordWrap: 'break-word' }}>{ad.description}</p>
                {ad.phone && <p>📞 {ad.phone}</p>}
                {ad.address && <p>📍 {ad.address}</p>}
            </Content>
        </CardContainer>
    );
}

export default React.memo(BusinessCard);
