import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';

const SearchBar = ({ data, setFilteredAds, fetchMoreAds, hasMore, searchTerm, setSearchTerm }) => {

    // Debounced version of fetchMoreAds
    const debouncedFetch = useMemo(
        () => debounce((term) => fetchMoreAds(term), 300),
        [fetchMoreAds]
    );

    const handleSearchChange = async (event) => {
        const term = event.target.value;

        // Clear search term logic
        if (term === '') {
            setSearchTerm('');
            setFilteredAds(data); // Temporarily reset to current data

            // Wait for any ongoing fetch to complete
            if (hasMore) {
                debouncedFetch(''); // Pass empty term to fetch more ads
                setFilteredAds(data); // Ensure filteredAds reflects updated data
            }

            return;
        }

        // Filtering logic for non-empty search term
        const lowerTerm = term.toLowerCase();
        setSearchTerm(lowerTerm);

        const filteredData = data.filter((item) => {
            const itemDescription = item.description ? item.description.toLowerCase() : '';
            const itemName = item.name ? item.name.toLowerCase() : '';
            return itemDescription.includes(lowerTerm) || itemName.includes(lowerTerm);
        });

        setFilteredAds(filteredData);

        if (hasMore) {
            debouncedFetch(lowerTerm); // Pass the search term to fetch more ads
        }
    };



    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: { xs: '90%', sm: '70%', md: '40%', lg: '30%' }, // Responsive width
                margin: 'auto',
                mt: 4,
                borderRadius: '8px', // Rounded corners
            }}
        >
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                slotProps={{
                    inputLabel: {
                        shrink: false, // Prevent Material-UI from automatically shrinking
                        sx: {
                            position: 'absolute',
                            top: '50%', // Center vertically when not focused
                            left: '50%', // Center horizontally
                            transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
                            textAlign: 'center',
                            pointerEvents: 'none', // Prevent interaction with the label
                            transition: 'all 0.3s ease', // Smooth transition
                            backgroundColor: '#fff', // Add background to avoid overlap
                            padding: '0 4px', // Add padding to prevent text clipping
                            '&.Mui-focused': {
                                top: '-10px', // Move label above the input
                                left: '8px', // Align with the input's padding
                                transform: 'translate(0, 0)', // Remove centering transform
                                fontSize: '0.75rem', // Smaller font size when focused
                            },
                        },
                    },
                    input: {
                        sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'inherit', // Use default border color
                            },
                        },
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        alignItems: 'center', // Center input content vertically
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#1976d2', // Primary color when focused
                        },
                        borderRadius: '25px', // Fully rounded input field
                        backgroundColor: '#ffffff', // White input background
                        transition: 'all 0.3s ease', // Smooth transition for effects
                        '&:hover': {
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)', // Shadow on hover
                        },
                    },
                }}
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </Box>
    );
};

export default SearchBar;
