import React from 'react';
import '../Stats.css';

const StatsBox = ({ icon, count, label }) => {
    return (
        <div className="stats-box">
            <div className="stats-icon">{icon}</div>
            <span>{count}</span> {label}
        </div>
    );
};

export default StatsBox;
