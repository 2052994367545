import { useEffect } from 'react';

const TrackPageVisits = ({ location, debug, baseUrl }) => {

    useEffect(() => {
        if (!location || location === 'Loading...') {
            // Wait until the location is resolved
            return;
        }

        const visited = localStorage.getItem('has_visited');
        if (!visited) {
            // Mark user as visited in localStorage
            localStorage.setItem('has_visited', 'true');

            // Send a request to update visit count
            const updateVisit = async () => {
                try {
                    const response = await fetch(`${baseUrl}/update_visit`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ location, debug }),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to update visit count');
                    }

                } catch (error) {
                    console.error('Error updating visit count:', error);
                }
            };

            updateVisit();
        }
    }, [location, debug, baseUrl]);

    return null; // No UI needed for this component
};

export default TrackPageVisits;
